
































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Homeslider extends Vue {
  offset = 0;
  leftScrollFlag = true;
  slides = [
    {
      title: "Slide #1",
      content: "Slide content."
    }
  ];
  get carousel() {
    const car: any = this.$refs.car;
    return car;
  }

  get cardMarginRight() {
    return Number(this.cardStyle.marginRight.match(/\d+/g)[0]);
  }

  get cardMarginLeft() {
    return Number(this.cardStyle.marginLeft.match(/\d+/g)[0]);
  }

  // Count the number of total cards you have
  get cardCount() {
    return this.carousel.querySelectorAll("[data-target='card']").length;
  }

  get card() {
    return this.carousel.querySelector("[data-target='card']");
  }

  get carouselWidth() {
    return this.carousel.clientWidth;
  }

  get cardStyle() {
    return this.card.currentStyle || window.getComputedStyle(this.card);
  }

  get maxX() {
    return this.carouselWidth - this.cardMarginRight;
  }
  get maxScroll() {
    return this.cardCount * 200 + this.cardCount * 16;
  }

  moveToLeft() {
    
    console.log(this.offset);
    if (this.offset == 0) {
      return;
    }
    if (Math.abs(this.offset) >= this.carousel.offsetWidth) {
      this.offset = 0;
    } else {
      this.offset += this.carousel.offsetWidth;
    }

    this.carousel.style.transform = `translateX(${this.offset}px)`;
    //}
    console.log(this.offset);
  }

  moveToRight() {
   
    console.log("totalCards:" + this.maxScroll);
    console.log("before offset: " + this.offset);
    this.offset -= this.carousel.offsetWidth;
    const abs_offset = Math.abs(this.offset);

    console.log("after offset: " + this.offset);
    console.log("abs offset: " + abs_offset);

    const max = this.carousel.offsetWidth;
    const diff = this.maxScroll + this.offset;

    const rem = this.maxScroll - abs_offset;
    console.log("rem: " + rem);
    console.log("max scroll: " + this.maxScroll);

    if (rem < this.carousel.offsetWidth) {
      // this.offset = -1 * this.maxScroll;
      //this.leftScrollFlag = false;
      console.log("ddd" + (this.carousel.offsetWidth - 760));
      console.log("dwww" + this.carousel.offsetWidth);
      this.offset -= -1 * (this.carousel.offsetWidth - rem);
      console.log("dweww" + this.offset);
    }
    // this.offset = -1 * this.maxScroll;
    console.log("total: " + this.offset);

    // this.offset -= this.carousel.offsetWidth;// + this.cardMarginRight;
    // this.carousel.style.transform = `translateX(${-4300}px)`;
    this.carousel.style.transform = `translateX(${this.offset}px)`;

    console.log(this.offset);
  }

  onLeftButtonClicked(e: any) {
    this.moveToLeft();
  }

  onRightButtonClicked(e: any) {
    this.moveToRight();
  }
}
