




















































































import { Vue, Component } from "vue-property-decorator";
import incidentCategoriesStore from "@/store/modules/incidentCategories";

import commonstore from "@/store/modules/common";

import { Input, Button } from "element-ui";
import { IncidentCategories } from "@/store/models/incident";
import { AdminRouter } from "@/utils/routePathContsant";
import slider from "@/components/Homeslider.vue";

@Component({
  components: {
    Input,
    Button,
    slider
  }
})
export default class IncidentCategory extends Vue {
  image: string = "";
  fileList: any[] = [];
 
  get AdminRouter() {
    return AdminRouter;
  }
  get categories() {

    return incidentCategoriesStore.incidentCategories;
  }

  async created() {    
    await incidentCategoriesStore.clearFields();
    
    
    const id = this.$route.query["id"];
    if (id) {
      await incidentCategoriesStore.loadIncidentCategoryById(id);
      console.log(incidentCategoriesStore.loadIncidentCategoryById(id));
      const { Icon, IconUrl } = this.categories;
      this.fileList.push({ name: Icon, url: IconUrl });
    }
  }
  handleChange(file: any) {
    let vm = this;
    vm.categories.Icon = file;
    this.fileList = [{ name: file.name }];
  }

  async submit(e: any) {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    await incidentCategoriesStore.saveIncidentCategory();
    await incidentCategoriesStore.clearFields();
    this.$snotify.success("Saved successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.incidentCategoryList);
  }
  cancel() {
    incidentCategoriesStore.clearFields();
    this.$router.push(AdminRouter.incidentCategoryList);
  }
  // IsCovidClicked(){
   
  //   this.categories.IsCovid =true;
  //     this.categories.IsComplain = false;
  //   // if(status == 'complain'){
  //   //   this.categories.IsCovid =false;
  //   //   this.categories.IsComplain = true;
  //   // }
  //   // else{
  //   //   this.categories.IsCovid =true;
  //   //   this.categories.IsComplain = false;
  //   // }
  // }
  //   IsComplainClicked(){
   
  //   this.categories.IsCovid =false;
  //     this.categories.IsComplain = true;
  //   // if(status == 'complain'){
  //   //   this.categories.IsCovid =false;
  //   //   this.categories.IsComplain = true;
  //   // }
  //   // else{
  //   //   this.categories.IsCovid =true;
  //   //   this.categories.IsComplain = false;
  //   // }
  // }
  handleCovidCheck(e:any){
      var result = e.target.checked;
      let vm = this;
      if(result){
      vm.categories.IsComplained = false;

      console.log('covid:' + true);
      console.log('complain:' + false);
      }
      else{
      vm.categories.IsComplained = false;
      vm.categories.IsCovid = false;
      }
      
  

  }
   handleComplainCheck(e:any){
    
    var result = e.target.checked;
      let vm = this;
      if(result){
      vm.categories.IsCovid = false;

      console.log('covid:' + false);
      console.log('complain:' + true);
      }
       else{
      console.log('covid:' + false);
      console.log('complain:' + false);
      vm.categories.IsComplained = false;
      vm.categories.IsCovid = false;
      }

  }
}
